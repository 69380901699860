import '../styles/globals.css'
import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'
import { ToastContextProvider } from "@byma/shared/contexts/ToastContext";
dayjs.locale('pt-br')

function MyApp({ Component, pageProps }) {
  return (
      <ToastContextProvider>
          <Component {...pageProps} />
      </ToastContextProvider>
  )
}

export default MyApp
