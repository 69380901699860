import React, { useCallback, useEffect, useState, createContext } from "react";

const ToastContext = createContext();

export default ToastContext;

export function ToastContextProvider({ children }) {
    const [toasts, setToasts] = useState([]);

    useEffect(() => {
        if (toasts.length > 0) {
            const timer = setTimeout(
                () => setToasts((toasts) => toasts.slice(1)),
                3000
            );
            return () => clearTimeout(timer);
        }
    }, [toasts]);

    const addToast = useCallback(
        function (text, level = 'success') {
            setToasts((toasts) => [...toasts, { text, level }]);
        },
        [setToasts]
    );

    function removeToast(toastIndex) {
        setToasts(toasts.filter((t, index) => index !== toastIndex))
    }

    return (
        <ToastContext.Provider value={addToast}>
            {children}
            {toasts.length > 0 && (
                <div className="fixed top-0 right-0 p-6 z-50">
                    {toasts.map((toast, index) => (
                        <div className={`flex justify-between items-center toast cursor-pointer bg-white w-64 rounded px-6 py-3 transition-all duration-400 mb-3 border-t-4 ${toast.level === 'success' ? 'border-green-600' : 'border-red-600' } shadow-md hover:shadow-lg`} key={index} onClick={() => removeToast(index)}>
                            {toast.text}
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                <path d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z" />
                            </svg>

                        </div>
                    ))}
                </div>
            )}
        </ToastContext.Provider>
    );
}
